var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay && _vm.hasOneVisible && _vm.data.length > 0)?_c('div',{class:_vm.getGaps},[(_vm.isReadyToDisplay && _vm.type === 'session')?_c('session-info-component',_vm._b({class:_vm.cssClass,attrs:{"display-hall":_vm.displayHall}},'session-info-component',_vm.data[0],false)):(_vm.isReadyToDisplay && _vm.type === 'communityUser')?_c('community-user-info-component',{class:_vm.cssClass,attrs:{"data":_vm.data}}):(_vm.isReadyToDisplay
      && _vm.type === 'deal'
      && _vm.dataType
      && _vm.dataType === 'details')?_c('deal-info-component',_vm._b({class:_vm.cssClass},'deal-info-component',_vm.data[0],false)):(_vm.isReadyToDisplay
      && ((_vm.type === 'deal' && _vm.dataType && _vm.dataType === 'product')
        || (_vm.type === 'product' && _vm.dataType && _vm.dataType === 'deal')
        || (_vm.type === 'deal' && _vm.dataType && _vm.dataType === 'largeProduct')
        || (_vm.type === 'largeProduct' && _vm.dataType && _vm.dataType === 'deal')))?_c('entity-info-links-component',_vm._b({class:_vm.cssClass},'entity-info-links-component',_vm.entity,false)):(_vm.isReadyToDisplay && ['product', 'largeProduct'].includes(_vm.type))?_c('product-info-component',_vm._b({class:_vm.cssClass,attrs:{"data-type":_vm.dataType,"display-hall":_vm.displayHall,"is-large-product":_vm.type === 'largeProduct',"schema-code":_vm.schemaCode}},'product-info-component',_vm.data[0],false)):(_vm.isReadyToDisplay && _vm.type === 'topics' && _vm.dataType && _vm.dataType === 'details')?_c('topics-details-component',_vm._b({class:_vm.cssClass},'topics-details-component',_vm.data[0],false)):(_vm.isReadyToDisplay && _vm.type === 'topics' && _vm.dataType && _vm.dataType === 'about')?_c('topics-about-component',_vm._b({class:_vm.cssClass},'topics-about-component',_vm.data[0],false)):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }