























































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import DealInfoComponent from '@/components/entity-info/DealInfoComponent.vue';
import ProductInfoComponent from '@/components/entity-info/ProductInfoComponent.vue';
import CommunityUserInfoComponent from '@/components/entity-info/CommunityUserInfoComponent.vue';
import SessionInfoComponent from '@/components/entity-info/SessionInfoComponent.vue';
import TopicsDetailsComponent from '@/components/entity-info/TopicsDetailsComponent.vue';
import TopicsAboutComponent from '@/components/entity-info/TopicsAboutComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import EntityInfoLinksComponent from '@/components/entity-info/EntityInfoLinksComponent.vue';

@Component({
  components: {
    EntityInfoLinksComponent,
    DealInfoComponent,
    SessionInfoComponent,
    CommunityUserInfoComponent,
    ProductInfoComponent,
    TopicsDetailsComponent,
    TopicsAboutComponent,
  },
})
export default class EntityInfoComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'EntityInfoWidgetStore';

  @Prop({
    required: false,
    default: false,
  })
  private readonly displayHall!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly entityCode!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly type!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly schemaCode!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly dataType!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly data!: Data[];

  created(): void {
    this.setDataConfig();
  }

  get hasOneVisible(): boolean {
    return !!(this.type === 'session'
      || this.type === 'communityUser'
      || (this.type === 'deal' && this.dataType && this.dataType === 'details')
      || (((this.type === 'deal' && this.dataType
          && (this.dataType === 'product' || this.dataType === 'largeProduct'))))
      || ['product', 'largeProduct'].includes(this.type)
      || (this.type === 'topics' && this.dataType && this.dataType === 'details')
      || (this.type === 'topics' && this.dataType && this.dataType === 'about'));
  }

  get cssClass(): string {
    const list: string[] = ['base-widget'];
    if (this.background) {
      list.push(this.WidgetBackground[this.background]);
    }
    if (this.classes) {
      list.push(this.classes);
    }
    return list.join(' ').trim();
  }

  get entity(): object {
    switch (this.dataType) {
      case 'deal':
        return {
          deal: this.data[0],
        };
      case 'product':
        return {
          product: this.data[0],
        };
      case 'largeProduct':
        return {
          largeProduct: this.data[0],
        };
      default:
        return {};
    }
  }
}
